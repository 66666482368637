var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.componentKey,ref:"productCardSelfscanning",staticClass:"product-card product-card-selfscanning",class:[_vm.promoClass],attrs:{"outlined":"","id":`product-card-${_vm.item.product.slug}`}},[_c('div',{staticClass:"product",on:{"click":function($event){return _vm.$emit('selectProduct')}}},[_c('div',{staticClass:"d-flex justify-space-between align-center w-100 top"},[_c('div',[_c('span',{staticClass:"cod-int text-caption"},[_vm._v(" Cod.Art "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.item.product.codInt))])])])]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column body"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.item.product.mediaURL,"onerror":"this.onerror=null;this.src='/no-icon.png'","alt":'Immagine' + _vm.item.product.name,"title":_vm.item.product.name +
              ' (' +
              _vm.item.product.codInt +
              '-' +
              _vm.item.product.codVar +
              ')'}}),_c('div',{staticClass:"description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"},[_c('span',{staticClass:"name font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('span',{staticClass:"short_descr"},[_vm._v(" "+_vm._s(_vm.item.product.description)+" "+_vm._s(_vm.item.product.shortDescr)+" "),(
                _vm.item.product.productInfos &&
                  _vm.item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                  _vm.item.product.priceUmDisplay
              )?_c('span',[_vm._v("al "+_vm._s(_vm.item.product.weightUnitDisplay)+" "+_vm._s(_vm.$n(_vm.item.product.priceUmDisplay, "currency")))]):_vm._e()]),(_vm.item.product.logisticPackagingItems)?_c('strong',[_c('span',{staticClass:"sep"},[_vm._v(" / ")]),_vm._v("IMBALLO "+_vm._s(_vm.item.product.logisticPackagingItems)+"pz")]):_vm._e(),(_vm.item.product.priceDisplay)?_c('ProductPrice',{attrs:{"product":_vm.item.product}}):_c('div',{staticClass:"product-price d-flex justify-center"},[_c('div',{staticClass:"cur-price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.unitPrice, "currency"))+" ")])]),_c('div',{staticClass:"iva align-self-end"},[_vm._v(" "+_vm._s(_vm.item.product.ivaCategory.descr)+" ")]),_c('span',{staticClass:"in-list-quantity py-2"},[_vm._v(" "+_vm._s(_vm.getDecodedListItemQuantity(_vm.item))+_vm._s(_vm.$n(_vm.item.product, "ssuffix"))+" ")]),_c('StepperSelfscanning',{attrs:{"item":_vm.item}}),_c('div',{staticClass:"product-price-total-container d-flex flex-row justify-end mt-4",attrs:{"cols":"4"}},[_c('div',{staticClass:"product-list-price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }