<template>
  <v-card class="popup-card selfscanning-container">
    <v-card-title v-if="selfCart && selfCart.cartItems == 0">
      Premi "Scansiona" per aggiungere prodotti al tuo carrello
    </v-card-title>
    <v-card-title v-if="!selfCart">
      Premi "Inizia" e scansiona il qrcode di inizio spesa per iniziare la tua
      spesa self
    </v-card-title>
    <v-card-text
      v-if="selfCart && selfCart.cartItems && selfCart.cartItems.length > 0"
    >
      <div class="product-grid product-grid-selfscanning mt-2 mt-sm-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(cartItem, index) in selfCart.cartItems"
            :key="cartItem.product.productId"
            class="product-col"
          >
            <ProductCardSelfscanning :item="cartItem" :position="index" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row
        v-if="!selfCart"
        class="product-buy-actions d-flex align-center justify-space-between no-cart"
      >
        <v-col class="d-flex flex-column" cols="12" v-if="!initCode">
          <v-btn
            class="button button-energized button-full my-2"
            width="100%"
            @click="init"
            >Inizia</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        v-if="selfCart"
        class="product-buy-actions d-flex align-center justify-space-between"
      >
        <v-col
          class="d-flex flex-column flex-end align-end"
          cols="12"
          v-if="initCode"
          ><span class="cart-total"
            >Totale:
            <strong>{{ $n(selfCart.grossTotal, "currency") }}</strong></span
          ></v-col
        >
        <v-col class="d-flex flex-column" cols="6" v-if="initCode">
          <v-btn
            class="button button-energized button-long my-2 secondary"
            @click="scan('add')"
            >Scansiona</v-btn
          >
        </v-col>
        <v-col class="d-flex flex-column" cols="6" v-if="initCode">
          <v-btn
            class="button button-positive button-short my-2 primary"
            @click="showOptions()"
            >Opzioni</v-btn
          >
        </v-col>
        <v-col class="d-flex flex-column" cols="6">
          <v-btn class="button button-positive button-short my-2" @click="abort"
            >Annulla ordine</v-btn
          >
        </v-col>
        <v-col class="d-flex flex-column" cols="6">
          <v-btn
            class="button button-positive button-short my-2"
            @click="terminate"
            >Concludi ordine</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.product-buy-actions {
  position: fixed;
  background-color: $white;
  bottom: 0px;
  left: 0px;
  border-top: 1px solid $primary;
  padding: 0px 10px 10px;
}
.product-buy-actions.no-cart {
  margin: 0px;
  width: 100%;
  border: none !important;
}
.selfscanning-container {
  height: calc(100% - 190px);
  position: absolute;
  overflow-y: auto;
  width: 95%;
  .cart-total {
    font-size: 18px;
  }
}
</style>
<script>
import ListService from "~/service/listService";
import SelfscanningService from "~/service/selfscanningService";
// import Popup from "@/components/Popup.vue";
import SelfscanningOptionsSelector from "@/components/selfscanning/SelfscanningOptionsSelector.vue";
import SelfscanningListSelector from "@/components/selfscanning/SelfscanningListSelector.vue";
import ProductCardSelfscanning from "@/components/product/ProductCardSelfscanning.vue";
import SelfscanningCompleted from "@/components/selfscanning/SelfscanningCompleted.vue";

export default {
  name: "Selfscanning",
  data() {
    return {
      STATUS_COMPLETED: 2,
      STATUS_NEED_MANUAL_INPUT: 3,
      selfCart: null,
      inputData: {},
      initCode: false
    };
  },
  components: { ProductCardSelfscanning },
  computed: {},
  mounted() {
    this.checkStatus();
  },
  methods: {
    async checkStatus() {
      let result = await SelfscanningService.view();
      this.updateSelfCart(result.data.data);
    },
    updateSelfCart(selfCart) {
      this.selfCart = selfCart;
      if (this.selfCart) {
        this.initCode = true;
      }
      global.EventBus.$emit("initQuantityPerUnit");
    },

    doUpdateSelfCart(selfCart) {
      this.selfCart = selfCart;
    },
    init() {
      var _this = this;
      this.scan("init", function() {
        _this.showRequest(
          "Carica lista",
          "Vuoi caricare una lista della spesa?",
          function() {
            _this.openListSelection();
          },
          function() {
            _this.initCode = true;
          }
        );
      });
    },
    terminate() {
      var _this = this;
      if (this.selfCart && this.selfCart.totalItems > 0) {
        this.showRequest(
          "Fine spesa",
          "Vuoi terminare la tua spesa? Inquadra il qrcode di chiusura spesa",
          function() {
            _this.scan("terminate");
          },
          function() {}
        );
      }
    },
    abort() {
      var _this = this;
      this.showRequest(
        "Annullamento spesa",
        "Vuoi annullare la tua spesa? Inquadra il qrcode di annullamento spesa",
        function() {
          _this.scan("abort", function() {
            _this.$router.push({
              name: "Home",
              path: "/"
            });
            _this.$emit("submit", true);
          });
        },
        function() {}
      );
    },
    async showRequest(title, message, yesFunction, noFunction) {
      let res = await this.$dialog.confirm({
        text: message
      });
      if (res) {
        console.log(res);
        yesFunction();
      } else {
        console.log(res);
        noFunction();
      }
    },
    async handleScan(code, action, callback, errorCallback, additionalParams) {
      var selfScanningFunction = null;

      switch (action) {
        case "init":
          selfScanningFunction = SelfscanningService.init;
          break;
        case "add":
          selfScanningFunction = SelfscanningService.addProduct;
          break;
        case "remove":
          selfScanningFunction = SelfscanningService.deleteProduct;
          break;
        case "terminate":
          selfScanningFunction = SelfscanningService.terminate;
          break;
        case "abort":
          selfScanningFunction = SelfscanningService.abort;
          break;
      }

      if (selfScanningFunction) {
        if (selfScanningFunction == SelfscanningService.addProduct) {
          additionalParams = 1;
          let resultFunc = await selfScanningFunction(code, additionalParams);
          if (
            resultFunc.data.response.status == 2 &&
            selfScanningFunction == SelfscanningService.terminate
          ) {
            var popupConfig = {
              title: "Ordine Completato",
              orderId: resultFunc.data.data.orderId
            };
            await this.$dialog.show(SelfscanningCompleted, {
              popup: popupConfig
            });
          }
          this.updateSelfCart(resultFunc.data.data);
        } else {
          var _this = this;
          selfScanningFunction(code, additionalParams).then(function(
            resultFunc
          ) {
            if (
              resultFunc.data.response.status == 2 &&
              selfScanningFunction == SelfscanningService.terminate
            ) {
              var popupConfig2 = {
                title: "Ordine Completato",
                orderId: resultFunc.data.data.orderId
              };
              _this.$dialog.show(SelfscanningCompleted, {
                popup: popupConfig2
              });
              _this.$emit("submit", true);
            } else {
              _this.updateSelfCart(resultFunc.data.data);
            }
          });
          // this.checkStatus();
        }

        if (callback) {
          callback();
        } else {
          if (errorCallback) {
            errorCallback();
          }
        }
      }
    },
    scan(actionStr, callback) {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            barcodeData => {
              if (barcodeData) {
                this.loading = false;
                if (
                  barcodeData &&
                  !barcodeData.cancelled &&
                  barcodeData.text.length
                ) {
                  this.handleScan(barcodeData.text, actionStr, callback);
                }
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              disableSuccessBeep: false
            }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        var code = prompt("Inserisci EAN", "");
        if (code) {
          this.handleScan(code, actionStr, callback);
        }
      }
    },
    async openListSelection() {
      //   this.loading();
      let personalLists = await ListService.getLists();

      var popupConfig = {
        title: "Seleziona la lista da caricare",
        lists: personalLists
      };
      await this.$dialog.show(SelfscanningListSelector, {
        popup: popupConfig
      });
    },
    getDecodedListItemQuantity(item) {
      var q = SelfscanningService.decodeListItemQuantity(item);
      if (q) {
        return "Quantità prevista: " + q;
      }
    },
    async showOptions() {
      var _this = this;
      var popupConfig = {
        title: "Opzioni disponibili: ",
        cart: _this.selfCart
      };
      await this.$dialog.show(SelfscanningOptionsSelector, {
        popup: popupConfig
      });
    }
  },
  created() {
    global.EventBus.$on("updateSelfCart", data => {
      this.updateSelfCart(data.data);
    });
    global.EventBus.$on("doUpdateSelfCart", data => {
      this.doUpdateSelfCart(data.data);
    });
    global.EventBus.$on("removeSelfItem", () => {
      this.scan("remove");
      // this.handleScan(code, "remove");
    });
  }
};
</script>
