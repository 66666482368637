<template>
  <v-card
    outlined
    class="product-card product-card-selfscanning"
    :class="[promoClass]"
    :id="`product-card-${item.product.slug}`"
    ref="productCardSelfscanning"
    :key="componentKey"
  >
    <div class="product" @click="$emit('selectProduct')">
      <div class="d-flex justify-space-between align-center w-100 top">
        <div>
          <span class="cod-int text-caption">
            Cod.Art
            <span class="font-weight-bold">{{
              item.product.codInt
            }}</span></span
          >
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="item.product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :alt="'Immagine' + item.product.name"
            :title="
              item.product.name +
                ' (' +
                item.product.codInt +
                '-' +
                item.product.codVar +
                ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name font-weight-bold">
              {{ item.product.name }}
            </span>
            <span class="short_descr">
              {{ item.product.description }} {{ item.product.shortDescr }}
              <span
                v-if="
                  item.product.productInfos &&
                    item.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    item.product.priceUmDisplay
                "
                >al {{ item.product.weightUnitDisplay }}
                {{ $n(item.product.priceUmDisplay, "currency") }}</span
              >
            </span>
            <strong v-if="item.product.logisticPackagingItems"
              ><span class="sep"> / </span>IMBALLO
              {{ item.product.logisticPackagingItems }}pz</strong
            >
            <ProductPrice
              v-if="item.product.priceDisplay"
              :product="item.product"
            />
            <div
              v-else
              class="
      product-price d-flex justify-center"
            >
              <div class="cur-price">
                {{ $n(item.unitPrice, "currency") }}
              </div>
            </div>
            <!-- <div
              v-else
              class="
      product-price d-flex justify-center"
            ></div> -->
            <div class="iva align-self-end">
              {{ item.product.ivaCategory.descr }}
            </div>
            <span class="in-list-quantity py-2">
              {{ getDecodedListItemQuantity(item)
              }}{{ $n(item.product, "ssuffix") }}
            </span>
            <StepperSelfscanning :item="item" />
            <div
              cols="4"
              class="product-price-total-container d-flex flex-row justify-end mt-4"
            >
              <div class="product-list-price">
                {{ $n(item.grossTotal, "currency") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  // .v-rating .v-icon {
  //   font-size: 16px;
  // }
  border: 1px solid #d5d5d5;
  border-radius: 12px 12px 12px 0px !important;
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .actions {
    min-height: auto !important;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin-right: 10px;
    }
  }

  .notAvailable {
    height: 66px;
    font-size: 16px !important;
  }

  .selectOptionsMenu {
    z-index: 12;
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .price-wraper {
    min-height: 60px;
  }
}
.product-card {
  transition: border-color 0.3s ease;
  &:hover {
    border: 1px solid $primary;
  }
}
.product-card-selfscanning {
  .sep {
    display: none;
  }
  span + strong {
    .sep {
      display: initial;
    }
  }
  .name {
    font-size: 14px;
  }
  .short_descr {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    min-height: 18px !important;
    -webkit-line-clamp: 1 !important;
  }
  .row-in-list {
    font-size: 12px;
  }
  .description {
    height: 260px !important;
  }
  .cur-price {
    font-size: 24px;
  }
  .old-price {
    font-size: 18px;
  }
  .product-list-price {
    font-size: 18px;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import SelfscanningService from "~/service/selfscanningService";
import ProductPrice from "@/components/product/ProductPrice.vue";
import StepperSelfscanning from "@/components/selfscanning/StepperSelfscanning.vue";
import { mask } from "vue-the-mask";

export default {
  name: "ProductCardSelfscanning",
  props: {
    item: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  components: { StepperSelfscanning, ProductPrice },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.item.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    // item() {
    //   this.key;
    //   let item = this.getItem({
    //     product: this.product,
    //     ...this.selectedOptions
    //   });
    //   return item;
    // },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.item.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.item.product.edge) {
        productClasses.push(this.item.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    },
    getDecodedListItemQuantity(item) {
      var q = SelfscanningService.decodeListItemQuantity(item);
      if (q) {
        return "Quantità prevista: " + q;
      }
    }
  },
  mounted() {
    if (this.item.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.item.product.selectOptions);
    }
  }
};
</script>
