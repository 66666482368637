import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";
import CatalogService from "~/service/catalogService";
import Qs from "qs";

export default {
  paramsSerializer: params => {
    return Qs.stringify(params, { arrayFormat: "brackets" });
  },
  decodeListItemQuantity(item, quantity, weight) {
    if (item.cartItemInfo) {
      var q =
        typeof quantity != "undefined" && quantity != null
          ? quantity
          : item.cartItemInfo.selQuantity;
      var w =
        typeof weight != "undefined" && weight != null
          ? weight
          : item.cartItemInfo.selWeight;
      quantity = CatalogService.decodeProductQuantity(item.product, q, w);
      return quantity;
    }
  },
  checkCode(code) {
    return axios
      .get("/ebsn/api/self-scanning/check_code", { params: { code: code } })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        }
      })
      .catch(data => {
        return data.response;
      });
  },
  init(code) {
    return axios
      .get("/ebsn/api/self-scanning/init", { params: { code: code } })
      .then(data => {
        return data;
      })
      .catch(data => {
        return data.data.response;
      });
  },
  terminate(code) {
    return axios
      .get("/ebsn/api/self-scanning/terminate", { params: { code: code } })
      .then(data => {
        return data;
      })
      .catch(data => {
        return data.data.response;
      });
  },

  abort(code) {
    return axios
      .get("/ebsn/api/self-scanning/abort", { params: { code: code } })
      .then(data => {
        return data;
      })
      .catch(data => {
        return data;
      });
  },

  view() {
    let params = {};
    // let today = dayjs().format("DD-MM-YYYY");
    params.hash = defaultParams().hash;
    return axios
      .get("/ebsn/api/self-scanning/view", {
        params,
        paramsSerializer: this.paramsSerializer
      })
      .then(data => {
        return data;
      })
      .catch(data => {
        return data;
      });
  },
  addProduct(code, quantity) {
    var params = { code: code, qty: quantity };
    params.hash = defaultParams().hash;
    return axios
      .post(
        "/ebsn/api/self-scanning/add_prod?hash=" +
          params.hash +
          "&code=" +
          params.code +
          "&qty=" +
          params.qty
      )
      .then(data => {
        return data;
      })
      .catch(data => {
        return data;
      });
  },
  deleteProduct(code) {
    var params = { code: code };
    params.hash = defaultParams().hash;
    return axios
      .post(
        "/ebsn/api/self-scanning/delete_prod?hash=" +
          params.hash +
          "&code=" +
          params.code
      )
      .then(data => {
        return data;
      })
      .catch(data => {
        return data;
      });
  },
  cloneFromList(listId) {
    var params = { list_id: listId };
    params.hash = defaultParams().hash;

    return axios
      .post(
        "/ebsn/api/self-scanning/clone_from_list?hash=" +
          params.hash +
          "&list_id=" +
          params.list_id
      )
      .then(data => {
        return data;
      })
      .catch(data => {
        return data;
      });
  },
  setItem(item, quantity) {
    //calcolo la quantity corretta in base al tipo di prodotto
    var decodedQuantity = CatalogService.encodeItemQuantity(
      item,
      quantity,
      quantity
    );
    decodedQuantity = parseInt(decodedQuantity);
    return axios
      .post("/ebsn/api/self-scanning/update_prods", {
        items: [{ itemId: item.itemId, quantity: decodedQuantity }],
        hash: defaultParams().hash
      })
      .then(data => {
        return data;
      })
      .catch(data => {
        return data.data.response;
      });
  }
};
