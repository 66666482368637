<template>
  <v-card class="popup-card">
    <v-card-title v-if="popup.title" class="popup-title">{{
      popup.title
    }}</v-card-title>
    <v-card-text class="align-center options-selector">
      <v-btn
        depressed
        color="white"
        text
        plain
        @click="manualInput()"
        class="button button-energized button-long my-2 w-100 secondary"
      >
        Inserimento manuale
      </v-btn>
      <v-btn
        class="button button-energized button-long my-2 w-100 secondary"
        depressed
        color="white"
        text
        plain
        @click="removeItem()"
      >
        Rimozione prodotto
      </v-btn></v-card-text
    >
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()"
        >Annulla</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
.v-dialog.vuedl-layout {
  .popup-card.v-card {
    .v-card__text {
      padding-top: 10px !important;
    }
  }
}
.popup-card {
  .popup-title {
    font-size: 20px;
  }
}
.options-selector {
  .v-btn {
    .v-btn__content {
      opacity: 1 !important;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import ManualInput from "@/components/selfscanning/ManualInput.vue";

export default {
  name: "SelfscanningOptionsSelector",
  props: {
    popup: { type: Object, required: true },
    categoryId: { type: Number, required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setPopupMap: "app/setPopupMap"
    }),
    submit() {
      this.setPopupMap(this.categoryId + "" + this.popup.TITLE);
      this.$emit("submit", true);
    },
    async manualInput() {
      var popupConfig = {
        title: "Inserisci il codice dell'articolo",
        removeItem: false
      };
      this.submit(true);
      await this.$dialog.show(ManualInput, {
        popup: popupConfig
      });
    },
    async removeItem() {
      // var popupRemoveConfig = {
      //   title: "Rimozione prodotto",
      //   removeItem: true
      // };
      this.submit(true);
      // await this.$dialog.show(ManualInput, {
      //   popup: popupRemoveConfig
      // });
      global.EventBus.$emit("removeSelfItem");
    }
  }
};
</script>
